const produce = {
  state: {
    produceInfo: null,
    indexSearchParams: null // 首页搜索参数
  },
  mutations: {
    set_produce_info (state, data) {
      state.produceInfo = data
    },
    set_index_search_params (state, data) {
      state.indexSearchParams = data
    }
  }
}

export default produce
